<template>
	<div>
		<navbar
			@toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
		></navbar>
		<sidenav ref="drawer"></sidenav>
		<v-container>
			<v-row justify="center">
				<v-col cols="12">
					<v-card class="pl-3 pr-3">
						<v-row>
							<v-tabs class="table_tabs ml-3 mt-3">
								<v-tab to="/">Overview</v-tab>
								<v-tab to="/footageuploads">Cameras</v-tab>
								<v-tab to="/inference">Inference</v-tab>
								<v-tab to="/services">Services</v-tab>
								<v-tab to="/system">System</v-tab>
								<v-tab to="/triton">Triton</v-tab>
							</v-tabs>
						</v-row>
						<v-container class="ml-3">
							<v-row no-gutters>
								<!-- <v-col cols="4" class="mr-3">
								<v-tabs v-model="selected_view" class="table_tabs">
									<v-tab>Models</v-tab>
									<v-tab>Metrics</v-tab>
								</v-tabs>
								</v-col> -->
								<v-spacer></v-spacer>
								<!-- <v-col cols="1" class="mr-3">
									<v-select
										v-model="func"
										label="Function"
										:items="func_list"
									></v-select>
								</v-col> -->
								<v-col cols="2" class="mr-3">
									<v-select
										v-model="time_range"
										label="Time Range"
										:items="time_ranges"
									></v-select>
								</v-col>
								<v-col cols="2" class="mr-3">
									<CameraAutoComplete
									:all="true"
									v-model="camera_id"
									:owner="selected_customer"
									></CameraAutoComplete>
								</v-col>
								<v-col cols="2" class="mr-3">
									<customer-filter
										v-model="selected_customer"
									></customer-filter>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" class="pl-0 pb-0">
									<grafana-graph
										:from="time_settings[time_range].from"
										:to="time_settings[time_range].to"
										:database="database"
										:table="table"
										:dashboard="dashboard"
										:user_id="user_id"
										:camera_id="camera_id"
										panelId="8"
										height="400"
									></grafana-graph>
								</v-col>
								<v-col cols="12" class="pl-0 pb-0">
									<grafana-graph
										:from="time_settings[time_range].from"
										:to="time_settings[time_range].to"
										:database="database"
										:table="table"
										:dashboard="dashboard"
										:user_id="user_id"
										:camera_id="camera_id"
										panelId="6"
										height="400"
									></grafana-graph>
								</v-col>
							</v-row>
							<!-- <v-container v-if="selected_view==0">
							<v-row v-for="(s, index) in model_list" :key="s + '_row'">
								<v-col
									cols="12"
									class="pl-0 pb-0"
									:class="{ 'pt-0': index == 0 }"
								>
									<h2>{{ s }}</h2>
									<v-divider></v-divider>
								</v-col>
								<v-col cols="12" class="pl-0 pb-0">
									<grafana-graph
										:from="time_settings[time_range].from"
										:to="time_settings[time_range].to"
										:database="database"
										:table="table"
										:dashboard="dashboard"
										:model_name="s"
										:func="func"
										:user_id="user_id"
										:camera_id="camera_id"
										panelId="2"
										height="300"
									></grafana-graph>
								</v-col>
							</v-row>
							</v-container>
							<v-container v-if="selected_view==1">
								<v-row v-for="(s, index) in metric_list" :key="s + '_row'">
									<v-col
										cols="12"
										class="pl-0 pb-0"
										:class="{ 'pt-0': index == 0 }"
									>
										<h2>{{ s }}</h2>
										<v-divider></v-divider>
									</v-col>
									<v-col cols="12" class="pl-0 pb-0">
										<grafana-graph
											:from="time_settings[time_range].from"
											:to="time_settings[time_range].to"
											:database="database"
											:table="table"
											:dashboard="dashboard"
											:metric_name="s"
											:func="func"
											:user_id="user_id"
											:camera_id="camera_id"
											panelId="4"
											height="300"
										></grafana-graph>
									</v-col>
								</v-row>
							</v-container> -->
						</v-container>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import navbar from "../components/Navbar.vue";
import sidenav from "../components/SideNav.vue";
import GrafanaGraph from "../components/GrafanaGraph.vue";
import { initializeTimestreamQueryClient } from "../utilities/sessionUtils";
import { QueryCommand } from "@aws-sdk/client-timestream-query";
import customerFilter from "../components/CustomerFilter.vue";
import moment from "moment";
import CameraAutoComplete from "../components/CameraAutoComplete.vue";
var queryClient;

// @vuese
// @group Views
export default {
	components: {
		navbar,
		sidenav,
		GrafanaGraph,
		customerFilter,
		CameraAutoComplete,
	},
	data() {
		return {
			selected_view: 0,
			database: "pipeline_metrics",
			table: "triton_metrics",
			dashboard: "triton-metrics",
			time_range: "24h",
			func: "AVG",
			func_list: ["AVG", "SUM", "MAX", "MIN"],
			selected_customer: "All Customers",
			user_id: "user_id",
			// model_list: [],
			model_loading: false,

			camera_id: null,
		};
	},
	created() {
		// this.getAllModels();
	},
	watch: {
		time_range() {
			// this.getAllModels();
		},
		selected_customer() {
			if (
				!this.selected_customer ||
				this.selected_customer == "All Customers"
			) {
				this.user_id = "user_id";
			} else {
				this.user_id = `'${this.selected_customer}'`;
			}
		},
		user_id() {
			// this.getAllModels();
		},
		camera_id() {
			// this.getAllModels();
		},
	},
	mounted() {},
	methods: {
		initializeQueryClient() {
			return initializeTimestreamQueryClient().then((client) => {
				queryClient = client;
			});
		},
		sortByKey(array, key) {
			return array.sort(function (a, b) {
				var x = a[key];
				var y = b[key];
				return x < y ? -1 : x > y ? 1 : 0;
			});
		},
		async getAllModels() {
			if (!queryClient) {
				await this.initializeQueryClient();
			}
			this.model_list = [];
			this.model_loading = true;
			await this.getModels();
			this.model_list.sort();
			this.model_loading = false;
		},
		async getModels(nextToken = undefined) {
			let response;
			var args = this.time_settings[this.time_range].moment_args;
			var date = moment();
			var to = date.valueOf();
			var from = date.subtract(args[0], args[1]).valueOf();
			var params = {
				QueryString: `
			SELECT DISTINCT model_name 
			FROM "${this.database}"."${this.table}"
			WHERE time BETWEEN from_milliseconds(${from}) AND from_milliseconds(${to})
			`,
				NextToken: nextToken,
			};
			var command = new QueryCommand(params);
			try {
				response = await queryClient.send(command);
			} catch (err) {
				console.error("Error while querying:", err);
				throw err;
			}

			console.log(response);
			this.model_list = [
				...this.model_list,
				...response.Rows.map((e) => {
					var value = e.Data[0].ScalarValue;
					return value;
				}),
			];
			if (response.NextToken) {
				await this.getModels(response.NextToken);
			}
		},
	},
	computed: {
		time_ranges() {
			return this.$store.getters.getTimeRanges;
		},
		time_settings() {
			return this.$store.getters.getTimeSettings;
		},
		model_list(){
			return this.$store.getters.getTritonModels;
		},
		metric_list(){
			return this.$store.getters.getTritonMetrics;
		}
	},
};
</script>
